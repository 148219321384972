import $ from 'jquery';
import Cookies from 'js-cookie';
import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Lenis from '@studio-freight/lenis';

import { Durations, Elements, ScrollableAttribute, ScrolledAttribute, SmoothScroll } from './constants';

// ---------- Register GSAP Plugins ----------

gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);

const refreshScrollTrigger = () => {
	ScrollTrigger.clearScrollMemory();
	window.history.scrollRestoration = 'manual';
	ScrollTrigger.refresh(true);
}

refreshScrollTrigger();
window.addEventListener('resize', refreshScrollTrigger);

// ---------- Init Smooth Scrolling ----------

export let lenis = null;

if(SmoothScroll) {

	lenis = new Lenis({
		duration: 2,
		wheelEventsTarget: document.body,
		smoothWheel: true,
		wheelMultiplier: 1,
		smoothTouch: true,
		touchMultiplier: 1.5,
	});

	function initLenis(time) {
		lenis.raf(time);
		requestAnimationFrame(initLenis);
	}

	requestAnimationFrame(initLenis);
}

// ---------- Helpers ----------

export const wait = (delay, value) => new Promise(resolve => setTimeout(resolve, delay, value));

export function escapeHTML(strings) {

	let result = strings[0];

	for(let i = 1; i < arguments.length; i++) {
		result += String(arguments[i]).replace(/[&<>'"]/g, (c) => {
			return { '&': '&amp;', '<': '&lt;', '>': '&gt;', '"': '&quot;', "'": '&#39;' }[c];
		});
		result += strings[i];
	}

	return result;
}

export function isTouchScreen() {
	return (('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0));
}

export function fixMobileHover() {

	if(isTouchScreen()) {

		const styleSheets = Array.from(document.styleSheets).filter(
			(styleSheet) => !styleSheet.href || styleSheet.href.startsWith(window.location.origin)
		);

		for(let style of styleSheets) {
			if(style instanceof CSSStyleSheet && style.cssRules) {
				for(let ruleI = style.cssRules.length - 1; ruleI >= 0; ruleI--) {
					let rule = style.cssRules[ruleI];
					if(rule.selectorText) {
						rule.selectorText = rule.selectorText.replace(':hover', ':active');
					}
				}
			}
		}
	}
}

export const mediaQueryString = (breakpoint, min = true) => (min) ? `(min-width: ${breakpoint}px)` : `(max-width: ${breakpoint - 1}px)`;

export function mediaQuery(breakpoint, before, after) {

	const handler = (query) => {
		if(typeof after === 'function' && query.matches) {
			after();
		}
		else if(typeof before === 'function') {
			before();
		}
	};

	if(window.matchMedia) {
		const query = window.matchMedia(mediaQueryString(breakpoint));
		query.addListener(handler);
		handler(query);
	}
}

export function elementPosition(element) {

	const rect = element.getBoundingClientRect();

	const scroll = scrollPosition();

	return {
		top: Math.round(rect.top + scroll.scrollTop - scroll.clientTop),
		left: Math.round(rect.left + scroll.scrollLeft - scroll.clientLeft),
		height: rect.height,
		width: rect.width,
	};
}


export function scrollPosition() {

	const root = document.documentElement;
	const body = document.body;

	const scrollTop = window.pageYOffset || root.scrollTop || body.scrollTop || 0;
	const scrollLeft = window.pageXOffset || root.scrollLeft || body.scrollLeft || 0;

	const clientTop = root.clientTop || body.clientTop || 0;
	const clientLeft = root.clientLeft || body.clientLeft || 0;

	return {
		scrollTop: scrollTop,
		scrollLeft: scrollLeft,
		clientTop: clientTop,
		clientLeft: clientLeft
	};
}

export function smoothScrollTo(element, duration = Durations.animations.s, offset = Elements.HeaderWrapper.offsetHeight, scroller = window) {

	if(SmoothScroll) {

		lenis.scrollTo(element, {
			duration: duration,
			offset: offset,
			immediate: (duration === 0),
			lock: true,
			force: true,
		});
	}
	else {

		gsap.to(scroller, {
			scrollTo: {
				y: element,
				offsetY: offset,
				autoKill: false
			},
			duration: duration,
			overwrite: true,
		});
	}
}

export function disableBodyScroll() {

	document.documentElement.style.scrollBehavior = 'auto';
	document.body.style.overflow = 'hidden';

	if(SmoothScroll) {
		document.body.setAttribute(ScrollableAttribute, false);
		lenis.stop();
	}
	else {

		const scrolled = scrollPosition();

		document.body.setAttribute(ScrolledAttribute, scrolled.scrollTop);
		document.body.style.position = 'fixed';
		document.body.style.top = -scrolled.scrollTop + 'px';
		document.body.style.left = 0;
		document.body.style.right = 0;
	}
}

export function enableBodyScroll() {


	if(SmoothScroll && document.body.hasAttribute(ScrollableAttribute)) {

		document.documentElement.style.scrollBehavior = '';
		document.body.style.overflow = '';
		document.body.removeAttribute(ScrollableAttribute);

		lenis.start();
	}
	else if(document.body.hasAttribute(ScrolledAttribute)) {

		const scrolled = parseInt(document.body.getAttribute(ScrolledAttribute), 10);

		document.body.style.position = '';
		document.body.style.top = '';
		document.body.style.left = '';
		document.body.style.right = '';
		document.body.style.overflow = '';
		document.body.removeAttribute(ScrolledAttribute);

		document.documentElement.scrollTo(0, scrolled);
		document.documentElement.style.scrollBehavior = '';
	}
}

export function toggleBodyScroll() {

	if(document.body.hasAttribute(ScrollableAttribute) || document.body.hasAttribute(ScrolledAttribute)) {
		enableBodyScroll();
	}
	else {
		disableBodyScroll();
	}
}

export function replaceBodyClasses(next) {

	const parser = new DOMParser();
	const newPage = parser.parseFromString(next.html, "text/html");
	const newBody = newPage.querySelector('body');

	document.body.className = newBody.getAttribute('class');
}

export function sortAnimations(animations) {

	let sortedAnimations = {};
	let animationsBreakpoints = {};

	Object.entries(animations).forEach(([breakpoint, value]) => {

		if(Array.isArray(value)) {
			sortedAnimations['all'] = animations.global;
		}
		else {
			sortedAnimations[mediaQueryString(value.width)] = (sortedAnimations[mediaQueryString(value.width)]) ? [...sortedAnimations[mediaQueryString(value.width)], ...value.min] : value.min;
			sortedAnimations[mediaQueryString(value.width, false)] = (sortedAnimations[mediaQueryString(value.width, false)]) ? [...sortedAnimations[mediaQueryString(value.width, false)], ...value.max] : value.max;
		}
	});

	Object.entries(sortedAnimations).forEach(([breakpoint, value]) => animationsBreakpoints[breakpoint] = () => value.map(animation => animation()));

	return animationsBreakpoints;
}

export function initGTM() {

	if(isCookiesAllowed()) {
		updateGTMConsent();
	}
}

export function updateGTMConsent() {

	if(typeof gtag === 'function') {

		gtag('consent', 'update', {
			'ad_storage': 'granted',
			'analytics_storage': 'granted',
			'ad_user_data': 'granted',
			'ad_personalization': 'granted',
			'personalization_storage': 'granted',
		});
	}
}

export function setCookiesAllowed(consent) {

	const cookie = (typeof cookies_params !== 'undefined') ? cookies_params.global.cookies_allowed : 'tc-cookies-allowed';

	Cookies.set(cookie, consent, {
		expires: 365,
		path: '/',
	});

	if(consent === 'true') {
		updateGTMConsent();
	}
}

export function getCookiesAllowed() {

	const cookie = (typeof cookies_params !== 'undefined') ? cookies_params.global.cookies_allowed : 'tc-cookies-allowed';

	return Cookies.get(cookie);
}

export function isCookiesAllowed() {
	return getCookiesAllowed() === 'true';
}

export function setDisclaimerAccepted() {

	const cookie = (typeof cookies_params !== 'undefined') ? cookies_params.global.disclaimer : 'tc-disclaimer-accepted';

	Cookies.set(cookie, true, {
		expires: 365,
		path: '/',
	});
}

export function getDisclaimerAccepted() {

	const cookie = (typeof cookies_params !== 'undefined') ? cookies_params.global.disclaimer : 'tc-disclaimer-accepted';

	return Cookies.get(cookie);
}

export function isDisclaimerAccepted() {
	return getDisclaimerAccepted() === 'true';
}

export function loadExternalScript(script) {
	const $script = $(script);
	const src = $script.attr('src');
	$('script[src="' + src + '"]').remove();
	$('body').append($script);
}

export function ajax(options = {}) {

	return new Promise((resolve, reject) => {

		$.ajax(options).done(response => {

			if(response.success) {
				resolve(response);
			}
			else {
				console.error(response);
				reject(response);
			}
		}).fail(error => {
			console.error(error);
			reject(error);
		});
	});
}

export function loadImage(src) {

	return new Promise((resolve, reject) => {
		const img = new Image();
		img.onload = () => resolve(img);
		img.onerror = reject;
		img.src = src;
	});
}

export function waitForImage(img) {

	return new Promise(resolve => {
		if(img.complete) {
			return resolve();
		}
		img.onload = () => resolve();
		img.onerror = () => resolve();
	});
}

export function waitForVideo(video) {
	return new Promise(resolve => {
		if(video.readyState >= 3) {
			return resolve();
		}
		video.addEventListener('loadeddata', () => resolve(), false);
	});
}


(r => r.keys().map(r))(require.context('../../svg/', true));
(r => r.keys().map(r))(require.context('../../images/', true, /\.(gif|png|jpe?g|svg)$/));
