import $ from 'jquery';
import Swiper from 'swiper';
import { EffectCreative, Navigation, Pagination, Parallax } from 'swiper/modules';

import { $Elements, Breakpoints, Classes, Durations, Events, tcVarName } from './constants';

$Elements.document.on(Events.page.enter, () => {

	// ---------- Swipers ----------

	const renderBullets = (swiper, current, total) => {

		const realTotal = (total / 2);
		const realCurrent = (current - 1) % realTotal;
		const realPrevious = (swiper.previousRealIndex) % realTotal;

		let style = `${tcVarName('bullet-width')}:${(1 / realTotal) * 100}%;`;

		style += `${tcVarName('bullet-from')}:${(realPrevious / realTotal) * 100}%;`;
		style += `${tcVarName('bullet-to')}:${(realCurrent / realTotal) * 100}%;`;

		return `<div class="bullet" style="${style}"></div>`;
	};

	$('.images-slider-section.left-slider .section-carousel').each(function() {

		if(!this.swiper) {

			const swiper = new Swiper(this, {
				modules: [EffectCreative, Pagination],
				createElements: true,
				observer: true,
				grabCursor: true,
				speed: Durations.default.ms,
				loop: true,
				loopAdditionalSlides: 3,
				slidesPerGroup: 1,
				pagination: {
					enabled: true,
					type: 'custom',
					renderCustom: renderBullets
				},
				effect: 'creative',
				slidesPerView: 1.2,
				creativeEffect: {
					limitProgress: 3,
					prev: {
						translate: ["-150%", 0, 0],
					},
					next: {
						translate: ["50%", 0, 0],
					},
				},
				breakpoints: {
					[Breakpoints.sm]: {
						slidesPerView: 1.6,
					},
				}
			});
		}
	});

	$('.images-slider-section.centered-slider .section-carousel').each(function() {

		if(!this.swiper) {

			const swiper = new Swiper(this, {
				modules: [EffectCreative, Pagination],
				createElements: true,
				observer: true,
				grabCursor: true,
				speed: Durations.default.ms,
				centeredSlides: true,
				loop: true,
				slidesPerGroup: 1,
				pagination: {
					enabled: true,
					type: 'custom',
					renderCustom: renderBullets
				},
				effect: 'creative',
				slidesPerView: 2,
				loopAdditionalSlides: 1,
				creativeEffect: {
					limitProgress: 2,
					prev: {
						translate: ["-100%", 0, 0],
					},
					next: {
						translate: ["100%", 0, 0],
					},
				},
				breakpoints: {
					[Breakpoints.sm]: {
						slidesPerView: 3,
						loopAdditionalSlides: 2,
						creativeEffect: {
							limitProgress: 2,
							prev: {
								translate: ["-125%", 0, 0],
							},
							next: {
								translate: ["125%", 0, 0],
							},
						},
					},
					[Breakpoints.md]: {
						slidesPerView: 5,
						loopAdditionalSlides: 4,
						creativeEffect: {
							limitProgress: 3,
							prev: {
								translate: ["-125%", 0, 0],
							},
							next: {
								translate: ["125%", 0, 0],
							},
						},
					}
				}
			});
		}
	});

	$('.wines-slider-section .section-carousel').each(function() {

		if(!this.swiper) {

			const swiper = new Swiper(this, {
				modules: [Navigation, Parallax],
				createElements: true,
				observer: true,
				grabCursor: true,
				speed: Durations.animations.ms,
				loop: false,
				parallax: true,
				navigation: true,
				centeredSlides: true,
				slidesPerGroup: 1,
				slidesPerView: 2,
			});
		}
	});

	$('.hero-section .millesimes-slider').each(function() {

		if(!this.swiper) {

			const active = parseInt(this.dataset.active, 10);

			const swiper = new Swiper(this, {
				createElements: true,
				observer: true,
				grabCursor: true,
				speed: Durations.animations.ms,
				loop: false,
				centeredSlides: true,
				slidesPerView: 'auto',
				initialSlide: active,
				watchSlidesProgress: true,
			});
		}
	});

	// ---------- Mute Button ----------

	$('.fullscreen-video-section').each(function() {

		$(this).find('.mute-btn').click(e => {

			$(e.currentTarget).toggleClass(Classes.active);

			const video = this.querySelector('.video');

			if(video) {
				video.muted = !video.muted;
			}
		});
	});
});
