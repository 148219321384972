import $ from 'jquery';
import { gsap } from 'gsap';

import { $Elements, Animations, Classes, Events, tcVarName } from '../global/constants';

// ---------- Init Scroll Animation ----------

export const initScrollAnimations = () => {

	// ---------- Menu ----------

	$Elements.HeaderWrapper.each(function() {

		if(!document.body.classList.contains('home')) {

			Animations.global.push(() => {

				gsap.timeline({
					scrollTrigger: {
						start: 1,
						end: 'max',
						onUpdate: (self) => {
							(self.direction === -1) ? this.classList.remove(Classes.shrink) : this.classList.add(Classes.shrink);
							(self.progress > 0) ? this.classList.add(Classes.shrinkable) : this.classList.remove(Classes.shrinkable);
						}
					}
				});
			});
		}
	});

	// ---------- Animated Section ----------

	$('.animated-section:not(.transition-clone)').each(function() {

		Animations.global.push(() => {

			const timeline = gsap.timeline({
				scrollTrigger: {
					trigger: this,
					once: true,
					start: 'top 80%',
					end: 'bottom bottom',
					toggleClass: Classes.active,
				}
			});
		});
	});

	// ---------- Content Sections ----------

	$('.parallax-section:not(.fixed):not(.transition-clone)').each(function() {

		Animations.global.push(() => {

			this.classList.add(Classes.moving);

			const image = this.querySelector(':scope .section-image');
			const speed = parseInt(this.dataset.speed, 10);

			if(image) {

				const timeline = gsap.timeline({
					scrollTrigger: {
						trigger: this,
						start: 'top bottom',
						end: 'bottom top',
						scrub: true,
						invalidateOnRefresh: true,
					},
				});

				const offset = () => image.offsetHeight - ((image.offsetHeight - this.offsetHeight) / 2);

				timeline.fromTo(
					image,
					{ y: () => offset() * (speed / 100), ease: 'none' },
					{ y: () => offset() * (-speed / 100), ease: 'none' }
				);
			}
		});
	});

	$('.hero-column.fullscreen-image:not(.home-column)').each(function() {

		Animations.global.push(() => {

			const image = this.querySelector(':scope .column-image');
			const content = this.querySelector(':scope .column-content .content');

			if(image) {

				const timeline = gsap.timeline({
					scrollTrigger: {
						trigger: this,
						start: (this.classList.contains('parallax-header')) ? 'top top' : 'top bottom',
						end: 'bottom top',
						scrub: true,
						invalidateOnRefresh: true,
					},
				});

				const offset = () => image.offsetHeight - ((image.offsetHeight - this.offsetHeight) / 2);

				timeline.to(
					image,
					{ y: () => offset() / 2, ease: 'none' },
				);

				if(this.classList.contains('parallax-header') && content) {

					timeline.to(
						content,
						{ y: '-15vh', ease: 'none' },
						0
					);

				}
			}
		});
	});

	$('.text-images-section').each(function() {

		Animations.sm.min.push(() => {

			const image = this.querySelector(':scope .big-image');
			const contentText = this.querySelector(':scope .small-image .column-content');
			const contentImage = this.querySelector(':scope .small-image .column-image');

			if(image) {

				const timeline = gsap.timeline({
					scrollTrigger: {
						trigger: this,
						start: 'top bottom',
						end: 'bottom top',
						scrub: true,
						invalidateOnRefresh: true,
					},
				});

				if(contentText && contentImage) {

					timeline.set(contentText, {
						[tcVarName('animation-offset-y')]: () => -(image.offsetHeight - contentText.offsetHeight - contentImage.offsetHeight) + 'px',
					});
				}

				timeline.fromTo(
					image,
					{ y: '-15vh', ease: 'none' },
					{ y: '15vh', ease: 'none' }
				);
			}
		});
	});

	$('.text-columns-section.full-content').each(function() {

		Animations.md.min.push(() => {

			const column = this.querySelector(':scope .left-column');

			if(column) {

				const timeline = gsap.timeline({
					scrollTrigger: {
						trigger: this,
						start: 'top bottom',
						end: 'bottom top',
						scrub: true,
						invalidateOnRefresh: true,
					},
				});

				timeline.fromTo(
					column,
					{ y: '-10vh', ease: 'none' },
					{ y: '10vh', ease: 'none' }
				);
			}
		});
	});

	$('.fullscreen-video-section').each(function() {

		Animations.menu.min.push(() => {

			const content = this.querySelector(':scope .section-columns');

			if(content) {

				const timeline = gsap.timeline({
					scrollTrigger: {
						trigger: this,
						start: 'top bottom',
						end: 'bottom top',
						scrub: true,
						invalidateOnRefresh: true,
					},
				});

				timeline.fromTo(
					content,
					{ y: '-25vh', ease: 'none' },
					{ y: '25vh', ease: 'none' }
				);
			}
		});
	});

	$('.wine-informations-section').each(function() {

		const content = this.querySelector(':scope .section-content');
		const columns = this.querySelector(':scope .section-columns');
		let animation = null;

		if(content && columns) {

			const contentColumns = gsap.utils.toArray('.content-column', columns);

			Animations.sm.max.push(() => {

				gsap.timeline({
					scrollTrigger: {
						trigger: this,
						pin: content,
						pinSpacing: false,
						scrub: true,
						invalidateOnRefresh: true,
						start: () => 'top top',
						end: () => 'bottom bottom',
					}
				});

				contentColumns.forEach((column, index, array) => {

					const animation = gsap.timeline({
						scrollTrigger: {
							trigger: column,
							scrub:true,
							invalidateOnRefresh: true,
							start: () => 'top 60%',
							end: () => 'bottom 60%',
						}
					});

					if(index < array.length - 1) {

						animation.to(column.querySelectorAll('.column-content .content *'), {
							color: 'transparent',
							ease: 'none',
						}).to(column, {
							opacity: 0,
						}, 1)
					}
				})
			});
		}
	});

	// ---------- Banners ----------

	$('.contact-banner, .scroll-top').each(function() {

		Animations.global.push(() => {

			gsap.timeline({
				scrollTrigger: {
					start: () => window.innerHeight,
					end: 'max',
					toggleClass: {
						targets: this,
						className: Classes.active,
					}
				}
			});
		});
	});
};

$Elements.document.on(Events.page.enter, () => initScrollAnimations());
