import $ from 'jquery';
import barba from '@barba/core';

import { $Elements, BarbaLinks, Classes, Durations, Elements, Events, pauseAnimations, resetAnimations } from '../global/constants';
import { disableBodyScroll, enableBodyScroll, replaceBodyClasses, scrollPosition, smoothScrollTo, wait, waitForImage, waitForVideo } from '../global/helpers';

// ---------- Barba Initialisation ----------

barba.init({
	debug: false,
	timeout: 10000,
	preventRunning: true,
	prevent: ({ el }) => !$(el).is(BarbaLinks),
	transitions: [{
		sync: true,
		once: async() => {

			$Elements.document.on('mousedown touchstart', BarbaLinks, (e) => {

				if((typeof (window.ontouchstart) !== 'undefined' && e.type === 'mousedown') || (e.which === 2 || e.which === 3 || e.button === 1 || e.button === 2 || e.metaKey || e.shiftKey || navigator.platform.toUpperCase().indexOf('WIN') !== -1 && e.ctrlKey)) return;

				const target = new URL(e.currentTarget.href);

				if(target.pathname !== window.location.pathname) {
					Elements.LoadingBar.classList.add(Classes.loading);
					document.body.classList.add(Classes.leaving);
				}
			});

			$Elements.window.on('load', pageLoaded);
			$Elements.document.trigger(Events.page.loaded);

			await wait(Durations.page.ms);

			Elements.MainWrapper.classList.remove(Classes.initialLoad);

			Elements.LoadingOverlay.classList.add(Classes.invisible);
			Elements.LoadingOverlay.classList.remove(Classes.initialLoad);

			await wait(Durations.page.ms);

			Elements.LoadingOverlay.classList.remove(Classes.invisible);
		},
		enter: async() => $Elements.document.one(Events.page.transition, pageLoaded),
		leave: async(data) => {

			Elements.LoadingBar.classList.add(Classes.loading);
			document.body.classList.add(Classes.leaving);

			pauseAnimations();

			if(isHomeLink(data)) {
				await homeTransition(data);
			}
			else if(isMenuLink(data)) {
				await menuTransition(data);
			}
			else {
				await defaultTransition(data);
			}

			return true;
		},
		after: async(data) => {

			smoothScrollTo(0, 0, 0);

			replaceBodyClasses(data.next);

			$('.' + Classes.transitionClone).remove();

			$Elements.document.trigger(Events.page.transition);

			return true;
		}
	}]
});

// ---------- Global ----------

async function pageLoaded() {

	resetAnimations();
	await waitForPage(Elements.PageWrapper, 0);
	$Elements.document.trigger(Events.page.enter);
	Elements.MainWrapper.classList.remove(Classes.loading);
	Elements.LoadingBar.classList.remove(Classes.loading);
	Elements.LoadingOverlay.classList.remove(Classes.loading);
	$Elements.ContentWrapper.find('.content-section:first-child').addClass(Classes.active);

	return true;
}

async function waitForPage(page, duration = Durations.page.ms) {

	await wait(duration);

	const heroVideo = page.querySelector(`.${Classes.ContentWrapper} > .content-section:first-child .video`);
	const heroImage = page.querySelector(`.${Classes.ContentWrapper} > .content-section:first-child .image`);

	if(heroVideo) {
		await waitForVideo(heroVideo);
	}
	else if(heroImage) {
		await waitForImage(heroImage);
	}

	return true;
}

async function saveCurrentScrollPosition(data) {

	const scroll = scrollPosition();

	data.current.container.classList.add(Classes.leaving);
	data.current.container.style.top = -scroll.scrollTop + 'px';

	smoothScrollTo(0, 0, 0);

	return true;
}

// ---------- Default Transition ----------

async function defaultTransition(data) {

	Elements.LoadingOverlay.classList.add(Classes.loading);

	await saveCurrentScrollPosition(data);

	disableBodyScroll();

	if(document.body.classList.contains(Classes.menuOpen)) {
		document.body.classList.remove(Classes.menuOpen);
	}

	await waitForPage(data.next.container);

	enableBodyScroll();

	return true;
}

// ---------- Menu Transition ----------

function isMenuLink(data) {
	return ($(data.trigger).hasClass('menu-link') && !$(data.trigger).closest('.footer-menu').length);
}

async function menuTransition(data) {

	await saveCurrentScrollPosition(data);

	disableBodyScroll();

	document.body.classList.add(Classes.menuTransition);

	Elements.LoadingOverlay.classList.add(Classes.loading);

	await wait(Durations.page.ms);

	document.body.classList.remove(Classes.menuOpen);

	await waitForPage(data.next.container, 100);

	document.body.classList.remove(Classes.menuTransition);

	enableBodyScroll();

	return true;
}

// ---------- Home Transition ----------

function isHomeLink(data) {
	return ($(data.trigger).hasClass('home-link') || $(data.trigger).closest('.home-page-link').length);
}

async function homeTransition(data) {

	const currentColumn = $(data.trigger).closest('.home-section .hero-column');
	const nextColumn = data.next.container.querySelector('.hero-section.fullscreen-image .hero-column');

	if(currentColumn.length && nextColumn) {

		data.next.container.classList.add(Classes.hidden);

		await saveCurrentScrollPosition(data);

		disableBodyScroll();

		const clone = currentColumn[0];
		clone.classList.add(Classes.transitionClone);

		await wait(Durations.default.ms);

		document.body.appendChild(clone);

		data.current.container.classList.add(Classes.hidden);
		data.next.container.classList.remove(Classes.hidden);

		clone.classList.remove(Classes.transitionClone, 'home-column');

		nextColumn.replaceWith(clone);

		await waitForPage(data.next.container, 100);

		enableBodyScroll();
	}
	else {
		await defaultTransition(data);
	}

	return true;
}
