import $ from 'jquery';
import Datepicker from 'vanillajs-datepicker/js/Datepicker';
import fr from 'vanillajs-datepicker/js/i18n/locales/fr';

Object.assign(Datepicker.locales, fr);


import { $Elements, Classes, Elements, Events } from '../global/constants';
import { ajax, disableBodyScroll, enableBodyScroll } from '../global/helpers';


// ---------- Handle Form ----------

function handleForm(form, params) {

	const formContent = form.find('.form-content');
	const formDone = form.find('.form-done');
	const formSuccess = formDone.find('.success');
	const formError = formDone.find('.error');
	const formButton = form.find('button[type=submit]');
	const date = form.get(0).querySelector('[name=date]');

	if(date) {

		const picker = new Datepicker(date, {
			buttonClass: 'btn',
			autohide: true,
			language: document.documentElement.attributes.lang.value || 'fr',
		});
	}

	form.on('submit', e => e.preventDefault());

	form.parsley().on('form:submit', async function() {

		Elements.LoadingBar.classList.add(Classes.loading);
		formButton.attr('disabled', true);

		const formData = new FormData(form[0]);
		formData.append('action', params.action);
		formData.append('nonce', params.nonce);

		try {

			const response = await ajax({
				url: params.url,
				type: 'POST',
				dataType: 'json',
				data: formData,
				processData: false,
				contentType: false,
			});

			formSuccess.removeClass(Classes.hidden);
		}
		catch(e) {
			formError.removeClass(Classes.hidden);
		}

		Elements.LoadingBar.classList.remove(Classes.loading);
		formButton.attr('disabled', false);
		formContent.addClass(Classes.hidden);
		formDone.removeClass(Classes.hidden);

		return false;
	});
}

$Elements.document.on(Events.page.enter, () => {

	// ---------- Reservation Form ----------

	if(typeof reservation_form_params !== 'undefined') {

		$('#reservation-modal').on('show.bs.modal', function(e) {
			const self = $(this);

			self.find('.form-content').removeClass('d-none');
			self.find('.form-done, .form-done .success, .form-done .error').addClass(Classes.hidden);

			disableBodyScroll();
		});

		$('#reservation-modal').on('hidden.bs.modal', function(e) {
			enableBodyScroll();
		});

		$('form.reservation-form').each(function() {
			handleForm($(this), reservation_form_params);
		});
	}

	// ---------- Contact Form ----------

	if(typeof contact_form_params !== 'undefined') {

		$('form.contact-form').each(function() {
			handleForm($(this), contact_form_params);
		});
	}

	// ---------- Store Locator Form ----------

	if(typeof store_locator_form_params !== 'undefined') {

		$('form.store-locator-form').each(function() {
			handleForm($(this), store_locator_form_params);
		});
	}

});
