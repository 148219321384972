import $ from 'jquery';
import { gsap } from 'gsap';

import { $Elements, Animations, Classes, Elements, Events, tcVarName } from '../global/constants';
import { lenis, smoothScrollTo } from '../global/helpers';

// ---------- Init Animation ----------

export const initHomeAnimations = () => {

	if(document.body.classList.contains('home')) {

		smoothScrollTo(0, 0, 0);

		Animations.global.push(() => {

			// ---------- Lenis settings ----------

			if(document.documentElement.classList.contains('touchscreen') && document.documentElement.classList.contains('desktop') &&
				document.documentElement.classList.contains('macintosh')) {
				lenis.options.infinite = true;
				lenis.options.touchMultiplier = .5;
				lenis.options.syncTouch = true;
				lenis.options.touchInertiaMultiplier = 1;
				lenis.options.smoothTouch = true;
			}
			else {
				lenis.options.infinite = true;
				lenis.options.touchMultiplier = .5;
				lenis.options.smoothTouch = true;
			}

			// ---------- Sections ----------

			let sections = gsap.utils.toArray('.home-section');

			sections.forEach((section, index) => {
				const btn = document.querySelector(`.link[data-section='${section.id}'] .btn`);

				const addActiveClassSection = () => {
					$(sections).removeClass([Classes.active, Classes.next]);
					$(section).addClass(Classes.active);

					if(index < sections.length - 1) {
						$(sections[index + 1]).addClass(Classes.next);
					}
					else {
						$(sections[0]).addClass(Classes.next);
					}
				}

				const addActiveClass = () => {
					addActiveClassSection();

					if(btn) {
						btn.classList.add(Classes.active);
					}
				}

				const removeActiveClass = () => {
					addActiveClassSection();

					if(btn) {
						btn.classList.remove(Classes.active);
					}
				}

				const timeline = gsap.timeline({
					scrollTrigger: {
						trigger: section,
						start: 'top top',
						end: 'bottom top',
						scrub: true,
						invalidateOnRefresh: true,
						onEnter: addActiveClass,
						onEnterBack: addActiveClass,
						onLeave: removeActiveClass,
						onLeaveBack: removeActiveClass,
					}
				});

				// ---------- Fade ----------

				if($(section).hasClass('home-page-link') || $(section).hasClass('home-wines-link')) {

					const column = section.querySelector(':scope .hero-column');

					if(column) {

						timeline.fromTo(column, {
							[tcVarName('opacity')]: 1,
						}, {
							[tcVarName('opacity')]: 0,
							ease: 'none',
						}, .75);
					}
				}

				// ---------- Quote ----------

				if($(section).hasClass('home-quote')) {

					const column = section.querySelector(':scope .hero-column');
					const content = section.querySelector(':scope .content');
					const quote = section.querySelector(':scope .quote');
					const author = section.querySelector(':scope .author');

					if(column) {

						timeline.fromTo(column, {
							[tcVarName('opacity')]: 1,
						}, {
							[tcVarName('opacity')]: 0,
							ease: 'none',
						}, .5);
					}

					if(content) {

						timeline.fromTo(content, {
							[tcVarName('scale')]: 1,
						}, {
							[tcVarName('scale')]: 100,
							ease: 'none',
						}, .5);
					}

					if(quote) {

						timeline.fromTo(quote, {
							[tcVarName('position')]: '0%',
						}, {
							[tcVarName('position')]: '-50%',
							ease: 'none',
						}, 0);
					}

					if(author) {

						timeline.set(author, {
							css: { className: 'author ' + Classes.active }
						}, .33);
					}
				}

				// ---------- Navigation ----------

				const navigation = Elements.ContentWrapper.querySelector('.home-navigation');

				if(navigation) {

					const link = navigation.querySelectorAll('.link[data-section=' + $(section).attr('id') + ']');

					if(link) {

						timeline.fromTo(link, {
							[tcVarName('progress')]: '0%',
						}, {
							[tcVarName('progress')]: '100%',
							ease: 'none',
							duration: 1
						}, 0);

						$(link).find('button').on('click', () => smoothScrollTo(section, 0, 1));
					}
				}
			});
		});
	}
	else {
		lenis.options.infinite = false;
		lenis.options.touchMultiplier = 1.5;
		lenis.options.smoothTouch = false;
	}
};

$Elements.document.on(Events.page.enter, () => initHomeAnimations());
